<template>
	<div class="container">
		<div class="space-y-10 p-6 h-full">
			<div class="p-2 space-y-4 border-2 border-gray-300 bg-gray-100">
				<table>
					<tbody>
						<tr class="flex w-full">
							<td class="flex w-1/4 ml-6">
								<div>시험기간</div>
								<div class="ml-6">{{ this.$_commonlib.formatDate(initData.examStartDt, 'YYYY-MM-DDTHH:mm:ss', 'YYYY.MM.DD') }}</div>
							</td>
							<td class="flex w-1/4">
								<div>시험시간</div>
								<div class="ml-9">{{ formatTime(initData.examStartTime) }} ~ {{ formatTime(initData.examEndTime) }}</div>
							</td>
							<td class="flex w-1/4">
								<div>시험시간(분)</div>
								<div class="ml-6">{{ diffMinutes() }}</div>
							</td>
							<td class="flex w-1/4">
								<div>문제 수</div>
								<div class="ml-6">{{ initData.sheetRegCnt }}</div>
							</td>
						</tr>
						<tr class="flex w-full mt-4">
							<td class="flex w-1/4 ml-6">
								<div>응시자명</div>
								<div class="ml-6">{{ initData.agtNm }} [ {{ initData.targetId }} ]</div>
							</td>
							<td class="flex w-1/4">
								<div>응시자 소속</div>
								<div class="ml-4">{{ initData.deptNmPath }}</div>
							</td>
							<td class="flex w-1/4">
								<div class="text-xl"></div>
								<div class="ml-12 text-xl text-red-500"></div>
							</td>
							<td class="flex w-1/4">
								<div></div>
								<div class="ml-4"></div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
						
			<div class="flex space-x-4 h-4/5">
				<div class="w-3/5">
					<div class="text-2xl p-2">{{ initData.examNm }}</div>
					<div class="border-2 px-4 pb-3 h-5/6">
						<div class="flex gap-2 my-4 relative">
							<div class="text-2xl">{{ questionData.questionRDOrd }}.</div>
							<div class="textSize" v-html="questionData.qustionNm"></div>
							<div class="text-xl ml-4 text-red-500 w-32">(배점 {{ questionData.maxScore }}점)</div>
							<div v-if="questionData.fileGroupId">
								<DxButton icon="download" hint="다운로드" @click="onDownload" />
							</div>
						</div>
						<div class="h-1/2 relative overflow-y-auto">
							<div v-if="questionData.answerTypeCd == 996 || questionData.answerTypeCd == 997" class="pl-20 space-y-4">
								<div class="flex space-x-4 items-center">
									<div class="text-xl text-black items-center">정답:</div>
									<DxTextBox
										class="border-2 border-black"
										v-model="questionData.answer"
										width="400"
										height="40"
										@value-changed="updateAnswer"
									>
									</DxTextBox>
								</div>
							</div>
							<div v-else v-for="(item1, index1) in questionData.questions" :key="index1" class="pl-20">
								<span>
									<DxCheckBox
										:enable-three-state-behavior="false"
										:value="item1.isChecked"
										@value-changed="e => checkAnswer(e, item1)"
									/>
								</span>
								<span class="text-xl ml-4">{{ item1.questionNumber }}.</span>
								<span class="text-xl ml-4">{{ item1.detailDescription }}</span>
							</div>
						</div>
					</div>

					<div class="flex justify-center space-x-6 items-center pt-2">
						<div>
							<DxButton
								text="< 이전"
								styling-mode="outlined"
								class="btn_XS white light_filled"
								:disabled="questionData.questionRDOrd <= 1"
								@click="goPrev"
							/>
						</div>
						<div>{{ questionData.questionRDOrd }}/{{ initData.sheetRegCnt }}</div>
						<div>
							<DxButton
								text="다음 >"
								styling-mode="outlined"
								class="btn_XS white light_filled"
								:disabled="questionData.questionRDOrd >= initData.sheetRegCnt"
								@click="goNext"
							/>
						</div>
					</div>
				</div>

				<div class="w-2/5">
					<div class="flex text-2xl p-2 space-x-5">
						<div class="flex">
							<div class="mr-2">전체 문제</div>
							<DxButton
								@click="answerCheck('all')"
								v-text="initData.sheetRegCnt"
								class="bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center"
							>
							</DxButton>
						</div>
						<div class="flex">
							<div class="mr-2">답변 완료</div>
							<DxButton
								@click="answerCheck('check')"
								v-text="initData.checkCnt"
								class="bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center"
							>
							</DxButton>
						</div>
						<div class="flex">
							<div class="mr-2">답변 미완료</div>
							<DxButton
								@click="answerCheck('noCheck')"
								v-text="initData.uncheckCnt"
								class="bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center"
							>
							</DxButton>
						</div>
						<div class="flex flex-grow gap-x-2 items-center justify-end">
							<div class="text-xl text-black tracking-wide">남은시간:</div>
							<div class="text-xl text-red-500 tracking-widest">{{ countDownTime }}</div>
						</div>
					</div>
					<div class="border-2 h-5/6">
						<DxDataGrid
							key-expr="questionId"
							:data-source="formData"
							:show-borders="false"
							:show-column-lines="true"
							:show-row-lines="true"
							width="100%"
							height="100%"
							:selected-row-keys="questionSelectedRowKeys"
							@selection-changed="selectionChangedData"
						>
							<DxFilterRow :visible="false" />
							<DxSelection mode="single" />
							<DxScrolling mode="virtual" />
							<DxLoadPanel :enabled="true" />

							<DxColumn
								caption="문항순서"
								data-field="questionRDOrd"
								alignment="center"
								:allowFiltering="false"
								:visible="true"
								:width="70"
							/>
							<DxColumn caption="답안" data-field="answer" alignment="center" :allowFiltering="false" :visible="true" />
							<DxColumn
								caption="정답유형"
								data-field="answerTypeCd"
								alignment="center"
								:allowFiltering="false"
								:visible="true"
								:width="70"
							>
								<DxLookup :data-source="codes.answerTypeCd.dataSource" value-expr="codeId" display-expr="codeNm" />
							</DxColumn>
							<DxColumn
								caption="배점"
								data-field="maxScore"
								alignment="center"
								:allowFiltering="false"
								:visible="true"
								:width="60"
							/>
							<DxColumn caption="대상자점수" data-field="answerScore" alignment="center" :visible="false" />
							<DxColumn caption="" cell-template="colorChangeTemplate" alignment="center" :visible="true" :width="40" />
							<template #colorChangeTemplate="{ data }">
								<div v-if="data.data.answer">
									<div class="w-4 h-4 bg-green-500"></div>
								</div>
								<div v-else>
									<div class="w-4 h-4 bg-gray-400"></div>
								</div>
							</template>
						</DxDataGrid>
					</div>
				</div>
			</div>

			<div class="bottom-btn-wrap">
				<DxButton text="제출" :width="100" :height="40" class="btn_XS default filled txt_S medium" @click="submitExamSheet" />
			</div>
		</div>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxDataGrid, DxColumn, DxLookup, DxFilterRow, DxSelection, DxScrolling, DxLoadPanel } from 'devextreme-vue/data-grid';

export default {
	components: {
		DxButton,
		DxTextBox,
		DxCheckBox,

		DxDataGrid,
		DxColumn,
		DxLookup,
		DxFilterRow,
		DxSelection,
		DxScrolling,
		DxLoadPanel,
	},
	props: {},
	watch: {},
	data() {
		return {
			initData: [], //초기
			targetData: [], //초기대상
			formData: [], //그리드
			countDownTime: '00:00',
			questionData: [], //문항
			targetAnswerData: [], //대상자 답안지
			originFormData: [], //그리드 원본
			selectedRowIndex: 0,
			questionSelectedRowKeys: [],
			showImages: [],
			checkBoxAnswer: false,
			checkText: null,
			codes: {
				answerTypeCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
			},
		};
	},
	computed: {},
	methods: {
		formatTime(time) {
			return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
		},
		diffMinutes() {
			const startTime = new Date();
			startTime.setHours(parseInt(this.initData.examStartTime.slice(0, 2), 10));
			startTime.setMinutes(parseInt(this.initData.examStartTime.slice(2, 4), 10));
			startTime.setSeconds(0);

			const endTime = new Date();
			endTime.setHours(parseInt(this.initData.examEndTime.slice(0, 2), 10));
			endTime.setMinutes(parseInt(this.initData.examEndTime.slice(2, 4), 10));
			endTime.setSeconds(0);

			const diffInMinutes = Math.floor((endTime - startTime) / (1000 * 60));
			return diffInMinutes;
		},
		onDownload() {
			const fileGroupId = this.questionData.fileGroupId;
			const fileName = this.questionData.fileName;
			this.$_downloadAttachFile(fileGroupId, fileName);
		},
		/** @description : 객관식 */
		async checkAnswer(e, questionData) {
			if (!e.event) return; //클릭으로 이벤트가 발생했을 때마다 실행

			questionData.isChecked = e.value;

			let newAnswerScore = 0;
			const newAnswerArray = this.questionData.questions
				.filter(questionNumber => questionNumber.isChecked == true)
				.map(questionNumber => String(questionNumber.questionNumber));

			if (this.questionData.scoringTypeCd == 1012) {
				//기본
				const allCorrect = this.questionData.questions
					.filter(questionNumber => questionNumber.score > 0)
					.every(questionNumber => questionNumber.isChecked == true);
				newAnswerScore = allCorrect ? this.questionData.maxScore : 0;
			} else if (this.questionData.scoringTypeCd == 1013) {
				//정답기준
				newAnswerScore = this.questionData.questions
					.filter(questionNumber => questionNumber.isChecked == true)
					.reduce((sum, questionNumber) => sum + questionNumber.score, 0);
			} else if (this.questionData.scoringTypeCd == 1014) {
				//오답기준
				const hasWrongAnswer = this.questionData.questions
					.filter(questionNumber => questionNumber.score == 0)
					.some(questionNumber => questionNumber.isChecked == true);
				if (hasWrongAnswer) {
					newAnswerScore = 0;
				} else {
					newAnswerScore = this.questionData.questions
						.filter(questionNumber => questionNumber.isChecked == true)
						.reduce((sum, questionNumber) => sum + questionNumber.score, 0);
				}
			}

			let data = [];
			data.push({
				id: this.questionData.id,
				scheId: this.initData.scheId,
				sheetId: this.initData.sheetId,
				questionId: questionData.questionId,
				agtId: this.initData.targetId,
				answer: newAnswerArray.join(','),
				score: newAnswerScore,
				delFl: 'N',
			});

			const payload = {
				actionname: 'EWM_PERSONAL_EXAM_ANSWER_SHEET_SAVE',
				data: {
					data: data,
				},
				loading: false,
			};

			const res = await this.CALL_EWM_API(payload);
			if (res.status === 200) {
				if (res.data.header.resCode === 'success') {
					this.questionData.id = res.data.data[0];

					this.$set(this.formData, this.selectedRowIndex, {
						...this.formData[this.selectedRowIndex],
						answer: newAnswerArray.join(', '),
					});

					this.$set(this.formData, this.selectedRowIndex, {
						...this.formData[this.selectedRowIndex],
						answerScore: newAnswerScore,
					});

					this.initData.uncheckCnt = this.formData.filter(item => !item.answer).length;
					this.initData.checkCnt = this.formData.filter(item => item.answer).length;
				} else {
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 주관식 */
		async updateAnswer(textValue) {
			let data = [];
			data.push({
				id: this.questionData.id,
				scheId: this.initData.scheId,
				sheetId: this.initData.sheetId,
				questionId: this.questionData.questionId,
				agtId: this.initData.targetId,
				answer: textValue.value,
				score: 0,
				delFl: 'N',
			});

			const payload = {
				actionname: 'EWM_PERSONAL_EXAM_ANSWER_SHEET_SAVE',
				data: {
					data: data,
				},
				loading: false,
			};

			const res = await this.CALL_EWM_API(payload);
			if (res.status === 200) {
				if (res.data.header.resCode === 'success') {
					this.questionData.id = res.data.data[0];
					
					this.$set(this.formData, this.selectedRowIndex, {
						...this.formData[this.selectedRowIndex],
						answer: textValue.value,
					});

					this.initData.uncheckCnt = this.formData.filter(item => !item.answer).length;
					this.initData.checkCnt = this.formData.filter(item => item.answer).length;
				} else {
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 이전문항 */
		goPrev() {
			if (this.selectedRowIndex == 0) {
				this.$_Msg('첫번째 문항입니다.');
			} else {
				--this.selectedRowIndex;
				this.questionData = this.formData[this.selectedRowIndex];

				this.questionSelectedRowKeys = [this.questionData.questionId];
			}
		},
		/** @description : 다음문항 */
		goNext() {
			if (this.selectedRowIndex < this.formData.length - 1) {
				this.selectedRowIndex++;
				this.questionData = this.formData[this.selectedRowIndex];

				this.questionSelectedRowKeys = [this.questionData.questionId];
			} else {
				this.$_Msg('마지막 문항입니다.');
			}
		},
		/** @description : 그리드 답안 체크 */
		answerCheck(type) {
			if (type === 'all') {
				//전체
				this.formData = this.originFormData;
				this.questionData = this.formData[0];
				this.selectedRowIndex = this.questionData.questionRDOrd - 1;
				this.questionSelectedRowKeys = [this.questionData.questionId];
			} else if (type === 'check') {
				//체크
				this.formData = this.originFormData.filter(item => item.answer);
				if (this.formData.length > 0) {
					this.questionData = this.formData[0];
					this.selectedRowIndex = this.questionData.questionRDOrd - 1;
					this.questionSelectedRowKeys = [this.questionData.questionId];
				}
			} else if (type === 'noCheck') {
				//미체크
				this.formData = this.originFormData.filter(item => !item.answer);
				if (this.formData.length > 0) {
					this.questionData = this.formData[0];
					this.selectedRowIndex = this.questionData.questionRDOrd - 1;
					this.questionSelectedRowKeys = [this.questionData.questionId];
				}
			}
		},
		/** @description : 로우 클릭 이벤트 */
		selectionChangedData(e) {
			this.questionSelectedRowKeys = e.selectedRowKeys;

			if (e.selectedRowsData.length > 0) {
				this.questionData = e.selectedRowsData[0];
				this.selectedRowIndex = this.questionData.questionRDOrd - 1;
			}
		},
		/** @description : 시험지 제출 */
		async submitExamSheet() {
			console.log('시험지 제출');
			let count = this.originFormData.filter(item => !item.answer).length;
			if (
				await this.$_Confirm(
					`<div style="text-align: center;">
                    <p>
                        시험을 제출하시겠습니까?<br>
                        (제출하셔도 재입장 가능합니다.)<br><br>
                        안 푼 문제: <span style="color: red;">${count}</span>
                    <p>
                </div>`,
					{ title: `<h2>시험진행</h2>` },
				)
			) {
				// this.saveExam();
				this.$router.push({ path: '/ewm/personal/exam' });
			} else {
				return false;
			}
		},
		async saveExam() {
			console.log('saveExam 실행됨ㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁㅁ');

			let data = this.formData.map(item => ({
				id: item.answerId,
				scheId: this.initData.scheId,
				sheetId: this.initData.sheetId,
				questionId: item.questionId,
				agtId: this.initData.targetId,
				answer: item.answer,
				score: item.answerScore,
				delFl: 'N',
			}));
			const payload = {
				actionname: 'EWM_PERSONAL_EXAM_ANSWER_SHEET_SAVE',
				data: {
					data: data,
				},
				loading: false,
			};
			const res = await this.CALL_EWM_API(payload);
			if (res.status === 200) {
				if (res.data.header.resCode === 'success') {
					this.$_Msg('채점이 완료되었습니다.');
				} else {
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		randomOrd(array) {
			for (let i = array.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				[array[i], array[j]] = [array[j], array[i]];
			}
			array.forEach((item, index) => {
				item.questionRDOrd = index + 1;
			});
		},
		/** @description: 데이터 조회 메서드 */
		async selectFormData() {
			const payload = {
				actionname: 'EWM_EXAM_SHEET',
				path: '/' + this.initData.sheetId + '/questions',
				loading: false,
				useErrorPopup: true,
			};
			const res = await this.CALL_EWM_API(payload);
			if (res.status === 200) {
				if (res.data.header.resCode === 'success') {
					if (this.initData.examQuestionRandFl === 'Y') {
						this.randomOrd(res.data.data);
					} else {
						res.data.data.sort((a, b) => a.questionOrd - b.questionOrd);
						res.data.data.forEach((item, index) => {
							item.questionRDOrd = item.questionOrd;
						});
					}
					res.data.data.forEach(d => {
						d.questions.sort((s, c) => s.questionNumber - c.questionNumber);
					});
					this.formData = res.data.data;
				}
			}
			const payload2 = {
				actionname: 'EWM_PERSONAL_EXAM_ANSWER_TARGET_LOG',
				data: {
					scheId: this.initData.scheId,
					sheetId: this.initData.sheetId,
					agtId: this.initData.targetId,
				},
				loading: false,
				useErrorPopup: true,
			};
			
			const res2 = await this.CALL_EWM_API(payload2);
			if (res2.status === 200) {
				if (res2.data.header.resCode === 'success') {
					console.log('2차 작성된 답안', res2.data.data);
					this.formData = this.formData.map(item => {
						const matchingAnswer = res2.data.data.find(targetItem => targetItem.questionId === item.questionId);
						return matchingAnswer ? { ...item, ...matchingAnswer } : item;
					});

					this.formData.forEach(i => {
						i.questions.forEach(j => {
							if (i.answer) {
								let answerArray = i.answer.split(',').map(a => Number(a.trim()));
								console.log('answerArray',answerArray)
								
								
									if (answerArray.includes(j.questionNumber)) {
										j.isChecked = true;
									}else{
										j.isChecked = false;
									}
							}else{
								j.isChecked = false;
							}
						});
					});

					if (this.formData.length > 0) {
						this.questionData = this.formData[0];
						this.selectedRowIndex = this.questionData.questionRDOrd - 1;

						this.questionSelectedRowKeys = [this.questionData.questionId];
					}

					this.initData.sheetRegCnt = this.formData.length;
					this.initData.uncheckCnt = this.formData.filter(item => !item.answer).length;
					this.initData.checkCnt = this.formData.filter(item => item.answer).length;
				}
				console.log('최종 formData', this.formData);
				console.log('문제 보여지는 questionData', this.questionData);
			}
			this.originFormData = this.formData;
		},
		/** @description : 남은 시간 카운트 */
		getCountDownTime() {
			const currentDateTime = new Date();
			const examStartDateTime = new Date(this.initData.examStartDt);
			const examStartHours = Math.floor(parseInt(this.initData.examEndTime) / 10000);
			const examStartMinutes = Math.floor(parseInt(this.initData.examEndTime) % 10000) / 100;
			examStartDateTime.setHours(examStartHours);
			examStartDateTime.setMinutes(examStartMinutes);
			examStartDateTime.setSeconds(0);

			const remainingDate = examStartDateTime - currentDateTime;
			const remainingHours = Math.floor((remainingDate / (1000 * 60 * 60)) % 24);
			const remainingMinutes = Math.floor((remainingDate / (1000 * 60)) % 60);
			const remainingSeconds = Math.floor((remainingDate / 1000) % 60);

			const pad = num => num.toString().padStart(2, '0');
			this.countDownTime = `${pad(remainingHours)}:${pad(remainingMinutes)}:${pad(remainingSeconds)}`;
			// this.countDownTime = `${pad(remainingMinutes)}:${pad(remainingSeconds)}`;
		},
		isCountDownNeeded() {
			const currentDateTime = new Date();
			const examStartDateTime = new Date(this.initData.examStartDt);
			const examStartHours = Math.floor(parseInt(this.initData.examEndTime) / 10000);
			const examStartMinutes = Math.floor(parseInt(this.initData.examEndTime) % 10000) / 100;
			examStartDateTime.setHours(examStartHours);
			examStartDateTime.setMinutes(examStartMinutes);
			examStartDateTime.setSeconds(0);

			return currentDateTime < examStartDateTime;
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_edu_exam_answer_type');
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			this.reqParams = this.$store.getters.getDetailParams;
			this.initData = this.reqParams.data;

			this.initCodeMap().then(() => {
				this.codes.answerTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_answer_type'], 2);
			});

			this.selectFormData();
		},
		/** @description : 라이프사이클 mounted 호출되는 메서드 */
		mountedData() {
			const interval = setInterval(() => {
				if (this.isCountDownNeeded()) {
					this.getCountDownTime();
				} else {
					clearInterval(interval);
					console.log('시험끝');
					this.$_Msg('시험시간이 끝났습니다. 자동으로 시험지가 제출됩니다.', { title: `<h2>시험종료</h2>` });
					this.saveExam();
					this.$router.push({ path: '/ewm/personal/exam' });
				}
			}, 1000);
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>

<style scoped>
.textSize {
	margin-left: 0.5em;
	font-size: 1.5rem;
	line-height: 2rem;
	max-width: 100%;
	max-height: 100%;
}

.textSize::v-deep * {
	font-size: 1.5rem;
	line-height: 2rem;
	max-width: 100%;
	max-height: 100%;
}
</style>

